import { useState } from "react";
import { environment } from "../../environment/environment";
import "../../vendors/styles/add_item.css";

const AddItem = () => {
    const [name, setName] = useState('');
    const [description, setDes] = useState('');
    const [feature, setFeature] = useState('');
    const [file, setFile] = useState<string | Blob>('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isUploading, setUploading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setUploading(true);

        const formData = new FormData();

        formData.append('name', name);
        formData.append('description', description);
        formData.append('feature', feature);
        formData.append('brand', 'JC Sakura');
        formData.append('image', file);
        formData.append('category', 'Used Machinery');
        formData.append('subCategory', 'Used Machinery');
        formData.append('spec', '');
        formData.append('video', '');
        formData.append('quantity', '1');
        formData.append('unitPrice', '0');

        const response = await fetch(`${environment.api_url}/admin/postaddproduct`, {
            method: 'POST',
            body: formData
        })

        const json = await response.json()

        if (json.success) {
            setError('');
            setSuccess(json.message);
            setName('');
            setDes('');
            setFeature('');
        } else {
            setSuccess('');
            if (json.message) {
                setError(json.message);
            } else {
                setError('Error!');
            }
        }
        setUploading(false);
    }

    return (
        <div className="container addContainer">
            <form className="create" onSubmit={handleSubmit}>
                <h3>Add New Product</h3>

                <label>Product Name:</label>
                <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />

                <label>Feature (if any):</label>
                <input
                    type="text"
                    onChange={(e) => setFeature(e.target.value)}
                    value={feature}
                />

                <label>Description:</label>
                <textarea
                    onChange={(e) => setDes(e.target.value)}
                    value={description}
                />

                <label>Image:</label>
                <input
                    type="file"
                    onChange={(e) => setFile(e.target.files![0])}
                />

                <button>Add Product</button>
                {isUploading ? <h5>Uploading...</h5> : null}
                {error && <div className="error">{error}</div> || success && <div className="success">{success}</div>}
            </form>
        </div>
    );
};

export default AddItem;