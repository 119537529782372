import "../../vendors/styles/product_list.css";
import { Card } from "react-bootstrap";
import letter1 from "../../vendors/images/letter1.png";
import sakura from "../../vendors/images/sakura.png";
import newTag from "../../vendors/images/card_new_green.png";
import usedTag from "../../vendors/images/used_card_red.png";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import React, { useEffect, useState } from "react";
import { environment } from "../../environment/environment";
import { Console } from "console";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../RouteNames";

const ProductList = () => {
  const history = useNavigate();
  const { cat_id, brand_id, category, brand } = useParams();
  const [toggled, setToggled] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };

  const [categoryId, setCategoryId] = useState(cat_id);
  const [brandId, setBrandId] = useState(brand_id);
  const [subCategoryId, setSubCategoryId] = useState(0);
  useEffect(() => {
    setIsLoading(true);
    fetch(`${environment.api_url}/product/getBrandsSubCategory/${brandId}`)
      .then((response) => response.json())
      .then((data) => {
        setCategoryList(data);
        setCurrentCategory(data[0]);
        setSubCategoryId(data[0].id);
      });
  }, []);
  useEffect(() => {
    if (subCategoryId != 0) {
      fetch(`${environment.api_url}/product/getSubCategoryProducts/category/${categoryId}/brand/${brandId}/subcategory/${subCategoryId}`)
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
          setIsLoading(false);
        });
    }
  }, [subCategoryId]);

  useEffect(() => {
    let tempCategory = categoryList.find((item: any) => item.id == subCategoryId);
    setCurrentCategory(tempCategory);
    setIsLoading(false);
  }, [subCategoryId]);

  const selectProduct = (item: any) => {
    history(RouteNames.PRODUCTDETAILS + `/${item.id}/${category}/${brand}`);
  };
  const getProductsView = () => {
    return products.map((item: any, i: number) => (
      <div key={i} className="col-md-6 col-sm-6 col-lg-4 col-xl-3 col-12 mb-3 d-flex justify-content-center">
        <Card onClick={() => selectProduct(item)} style={{ width: "18rem" }} className="shadow prod-list-card">
          <img src={category === 'Used Machinery' ? usedTag : newTag} loading="lazy" alt="used" className="used-tag" />
          <div className="card-bg">
            <Card.Img variant="top" src={`${environment.img_url}/${item.imageUrl}`} />
          </div>
          <Card.Body className="d-flex justify-content-center align-items-center">
            <Card.Title className="text-center title">{item.name}</Card.Title>
          </Card.Body>
        </Card>
      </div>
    ));
  };

  return (
    <div>
      <ProSidebar breakPoint="lg" toggled={toggled} onToggle={handleToggleSidebar} className="d-block d-lg-none">
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 14,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Select Category
          </div>
        </SidebarHeader>
        <Menu iconShape="square">
          {categoryList.map((item: any, i: number) => (
            <MenuItem
              key={i}
              onClick={() => {
                if (subCategoryId != item.id) {
                  setSubCategoryId(item.id);
                  setIsLoading(false);
                }
                setToggled(!toggled);
              }}
            >
              <i className="fa-solid fa-angle-right pe-2"></i>
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </ProSidebar>
      {/* <div className="company-banner-short">
        <div className="left-gradient">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <div className="logo-bg">
                      <img src={sakura}></img>
                    </div>
                  </div>
                  <div className="col-12">
                    <h1>
                      JC Sakura <br />
                      International
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="intro p-4">
                  <p>
                    JC Sakura International company being the agent for Kranzle products from Germany (through Internaco in Spain), Italclean and Grandimpianti from Italy, we specialize in laundry equipment through this company. Our deep
                    understanding of the laundry equipment industry allows us to provide bespoke commercial washing machine solutions for your business. In addition to the quality, reliability and performance you'd expect as standard, you can benefit
                    from business-focused features such as eco-friendly cycles, infection control technology and rigid mount or free-standing options — as well as customizable programs. Whatever your requirements are for laundry equipment, we can
                    provide you with everything you need to ensure your business thrives. Being the agent of Grandimpianti and ltalclean, we supply all solutions to your varied laundry equipment needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*   <div className="d-block d-lg-none ms-3 me-3">
        <div className="row pt-3">
          <div className="btn-toggle  col-3 d-flex align-items-center" onClick={() => handleToggleSidebar(!toggled)}>
            <i className="fa-solid fa-bars" style={{ fontSize: "30px" }}></i>
          </div>
          <div className="col-9 d-flex flex-row-reverse">
            <select style={{ width: "200px" }} className="form-select" name="" id="">
              <option value="select" className="font-italic">
                All
              </option>
              <option value="select" className="font-italic">
                Used
              </option>
              <option value="select" className="font-italic">
                New
              </option>
            </select>
            <br />
          </div>
        </div>
      </div> */}
      <div className="pt-4">
        <div className="container-fluid mt-5">
          <div className="text-center d-block d-lg-none">
            <img src={require(`./../../vendors/logos/${brand}.png`)} style={{ height: '40vh', width: '100%', objectFit: 'contain' }} />
          </div>
          <p className="breadcrumb">
            <Link to={RouteNames.ROOT}>Home</Link> &nbsp;&gt; <Link to={RouteNames.BRANDS + `/${category}/${cat_id}`}>{category}</Link> &nbsp;&gt; {brand}
          </p>
          <div className="row pt-3">
            <div className="col-md-3 d-none d-lg-block">
              <div className="sidebar-productList">
                <div className="d-none d-lg-block mb-3">
                  <img src={require(`./../../vendors/logos/${brand}.png`)} />
                </div>
                <h3>Select Category</h3>
                <div className="ps-3 pt-3">
                  {categoryList.map((item: any, i: number) => (
                    <p
                      key={i}
                      onClick={() => {
                        if (subCategoryId != item.id) {
                          setSubCategoryId(item.id);
                          setIsLoading(false);
                        }
                      }}
                    >
                      <i className="fa-solid fa-angle-right pe-2"></i>
                      {item.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-1 d-block d-lg-none">
              <div className="sidenav-burgerMenu">
                {" "}
                <div className="btn-toggle  col-3 d-flex align-items-center" onClick={() => handleToggleSidebar(!toggled)}>
                  <i className="fa-solid fa-bars" style={{ fontSize: "30px" }}></i>
                </div>
              </div>
            </div>
            <div className="col-11 col-lg-9">
              {/*   <div className="row d-none d-md-block mb-1">
                <div className="col-12 d-flex flex-row-reverse">
                  <select style={{ width: "20%" }} className="form-select font-italic" name="" id="">
                    <option value="select" className="font-italic">
                      All
                    </option>
                    <option value="select" className="font-italic">
                      Used
                    </option>
                    <option value="select" className="font-italic">
                      New
                    </option>
                  </select>
                  <br />
                </div>
              </div> */}

              <>
                <div className="mb-5">
                  <div className="brand-strip">{currentCategory?.name} </div>
                </div>

                <div className="products mt-3 mb-5">
                  <div className="mt-4">
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <h5 className="fa-fade">Loading...</h5>
                      </div>
                    ) : (
                      <div className="row mt-3">{getProductsView()}</div>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
