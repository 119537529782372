import '../../vendors/styles/home.css';
import { Card } from 'react-bootstrap';
import { RouteNames } from '../../RouteNames'

import washingMachine from '../../vendors/images/washing_machine.png';
import dryCleaner from '../../vendors/images/dry_cleaner.png';
import generator from '../../vendors/images/generator.png';
import dryer from '../../vendors/images/dryer.png';
import lawnmower from '../../vendors/images/lawn_mower.png';
import pharma from '../../vendors/images/pharma.png';
import excavator from '../../vendors/images/excavator.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import image1 from '../../vendors/images/x3.png';
import image2 from '../../vendors/images/x2-copy.png';
import image3 from '../../vendors/images/x1.png';
import image4 from '../../vendors/images/x4.png';
import image5 from '../../vendors/images/x5.png';
import image6 from '../../vendors/images/x6.png';
import logo1 from '../../vendors/images/slider-l6.png';
import logo2 from '../../vendors/images/slider-l5.png';
import logo3 from '../../vendors/images/slider-l4.png';
import logo4 from '../../vendors/images/slider-l3.png';
import logo5 from '../../vendors/images/slider-l2.png';
import logo6 from '../../vendors/images/slider-l1.png';
import logo7 from '../../vendors/images/slider-l7.png';
import benza from '../../vendors/images/benza.png';
import grand from '../../vendors/images/grand.png';
import italclean from '../../vendors/images/italclean.png';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {

  const history = useNavigate();

  const products = [
    { id: 1, title: 'Washing Machines', src: washingMachine, link: 'Laundry Items/1' },
    { id: 2, title: 'Dry Cleaning Machines', src: dryCleaner, link: 'Laundry Items/1' },
    { id: 3, title: 'Used Machinery', src: excavator, link: 'Used Machinery/7' },
    { id: 4, title: 'Generators', src: generator, link: 'Generators/4' },
    { id: 5, title: 'Lawn Mowers', src: lawnmower, link: 'Lawn Mowers/3' },
    { id: 6, title: 'Pharmaceuticals', src: pharma, link: 'Pharmaceuticals/6' },
  ];

  const logos = [
    { id: 1, title: 'logo1', src: logo1, path: RouteNames.SAKURA, name: "JC SAKURA INTERNATIONAL" },
    { id: 2, title: 'logo2', src: logo2, path: RouteNames.NEWGEN, name: "NEWGEN LIFESCIENCE (PVT) LTD" },
    { id: 3, title: 'logo3', src: logo3, path: RouteNames.TELLUSTEC, name: "TELLUSTEC CO. LTD" },
    { id: 7, title: 'logo7', src: logo7, path: RouteNames.MATSUDA, name: "Matsuda Kogyo GK" },
    { id: 4, title: 'logo4', src: logo4, path: RouteNames.TOKYOPIPE, name: "TOKYO PIPE ENGINEERING (PVT) LTD" },
    { id: 5, title: 'logo5', src: logo5, path: RouteNames.JCS, name: "JCS MACHINERY" },
    { id: 6, title: 'logo6', src: logo6, path: RouteNames.JCN, name: "JCN MACHINERY" }
  ];

  const slides = [
    { id: 1, title: 'slide1', src: image1, logo: benza, desLine1: 'SOLE AGENT IN SRI LANKA', deLine2: 'Comes from Spain' },
    { id: 2, title: 'slide2', src: image2, logo: grand, desLine1: 'AUTHORISED AGENT IN SRI LANKA', deLine2: 'Comes from Italy' },
    { id: 3, title: 'slide3', src: image3, logo: italclean, desLine1: 'SOLE AGENT IN SRI LANKA', deLine2: 'Comes from Italy' },
    { id: 4, title: 'slide4', src: image6, logo: undefined, desLine1: 'WE ARE THE SOLE IMPORTERS', deLine2: undefined },
  ];

  const selectProduct = (item: any) => {
    if (item.link) {
      history(RouteNames.BRANDS + `/${item.link}`);
    }
  };

  const getProductsView = products.map((item, i) =>
    <div key={i} className="col-md-4 col-sm-6 col-12 mb-3 d-flex justify-content-center">
      <Card onClick={() => selectProduct(item)} style={{ width: '18rem' }} className="shadow prod-list-card">
        <div className="card-bg">
          <Card.Img variant="top" src={item.src} />
        </div>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Card.Title className="text-center title">{item.title}</Card.Title>
        </Card.Body>
      </Card>
    </div>
  );

  const getLogoView = logos.map((item, i) =>
    <Link to={item.path} key={i}>
      <div className="col-2 col-sm-2 col-md-2 col-lg-12 logo-col">
        <div className='logo-wrapper d-flex justify-content-center align-items-center' data-placement="top" title={item.name}>
          <img className='logo' src={item.src} />
        </div>
      </div>
    </Link>
  );

  const getSlideView = slides.map((item, i) =>
    <div key={i} className='slider-wrapper'>
      <SwiperSlide>

        <div className="row sliderow">
        <div className="col-12 col-sm-6">
          {/* {
            (item.src2) ? (
              <img className="slide-image" src={item?.src2}/>
            )
             : undefined
          } */}
        </div>
          <div className="col-12 col-sm-6">
            <img className="slide-image" src={item.src} style={item.id != 4 ? {transform: "scaleX(-1)"} : undefined}/>
          </div>
          <div className="col-12 col-sm-12 d-flex align-items-center justify-content-start slide-details">
            <div className="slide-description">
              <img className={item.logo ? 'slide-logo' : undefined} src={item?.logo} />
              <h1>{item.desLine1}</h1>
              <h3>{item?.deLine2}</h3>
            </div>

          </div>
          
        </div>

      </SwiperSlide>


    </div>
  );

  return (

    <div>
      <div className="slide-bg">
        <div className="left-grad">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-1 col-sm-1 logo-col" >
                <div className='row logo-row'>
                  {getLogoView}
                </div>
              </div>
              <div className="col-11 col-sm-11">
                <Swiper spaceBetween={30}
                  loop={true}
                  centeredSlides={true}
                  autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: false }}
                  speed={3000}
                  pagination={false}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {getSlideView}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row aboutUsTitle">
        <h1>Who We Are</h1>
      </div>
      <div className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8 col-lg-6 pt-5 pb-5 mt-5">
              <div className="intro p-4 mt-4">
                <p>
                We are dedicated to bringing our clients the best by supplying goods of excellent quality from all over the world. Our broad international chain of suppliers makes this possible. JC Sakura International Group of Companies, one of the leading names in business in Sri Lanka, Japan and all over Europe, is a one-stop-shop for the entire range of superior laundry solutions, construction machinery, metal work machines, wood working machines, generators, cleaning equipment and pharmaceuticals.
                <br></br>
                <br></br>
                Years of experience in the sector and our commitment to always offer our clients the best world class products and services has helped us sustain and grow consistently.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-6">
            </div>
          </div>
        </div>
      </div>
      <div className="row productTitle">
        <h1>Our Products</h1>
      </div>
      <div className="products">
        <div className="container mt-4">
          <div className="row mt-3">
            {getProductsView}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home